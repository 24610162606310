import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import MapDep from '../../components/capeb/MapDep';
import MapReg from '../../components/capeb/MapReg';

import { stepColor } from '../../helpers/color';
import { customRound, scindeValues } from '../../helpers/legend';

import { useProjectContext } from '../../context/ProjectContext';

const nrAreaAttrs = {
    fill: '#fff',
    stroke: '#ddd',
};
const selectedAreaAttrs = {
    fill: '#5899d4',
    stroke: '#ddd',
};

const getDefaultPlotAttrs = (graphType, colors) => {
    let primary = '#c6362d';
    let mapColors = null;
    if (colors) {
        primary = colors.primary;
        mapColors = colors.mapColors;
    }

    return {
        fill: primary,
        stroke: mapColors ? mapColors.stroke : '#fff',
        'stroke-width': 1,
    };
};

// -----------------------------------
// Définie le texte de chaque slice de la légende
// -----------------------------------
const buildSlicesLabel = (slices, graphType, type) => {
    if (type !== 'area') {
        return slices;
    }

    return slices.map((slice, i) => {
        const isFirst = i === 0;
        const isLast = i === slices.length - 1;
        switch (graphType) {
            case 'capeb-nombre-adherent-et-evolution-dep':
                slice.label = `Evolution de ${slice.min}% à ${slice.max}%`;
                if (isLast) {
                    slice.label = `Evolution supérieure à ${slice.min}%`;
                }
                break;
            case 'capeb-taux-syndicalisation-dep':
            case 'capeb-charge-personnelle-charge-exploitation-reg':
            case 'charge-personnelle-charge-exploitation-dep':
            case 'capeb-taux-depart-dep':
            case 'capeb-taux-nouveaux-adherents-dep':
            case 'capeb-pourcentage-adherent-travaillant-dep':
            case 'capeb-pourcentage-adherents-employeurs-dep':
            case 'capeb-pourcentage-adherents-employeurs-nombre-adherents-dep':
            case 'capeb-pourcentage-adherents-travaillant-employeurs-dep':
            case 'capeb-montant-cotisations-syndicales-totales-produits-exploitation-dep':
            case 'capeb-montant-cotisations-syndicales-totales-produits-exploitation-reg':
            case 'capeb-subventions-contributions-exportation-produits-exportations-reg':
                slice.label = `Taux de ${slice.min}% à ${slice.max}%`;
                if (isFirst) {
                    slice.label = `Taux inférieur à ${slice.max}%`;
                }
                if (isLast) {
                    slice.label = `Taux supérieur à ${slice.min}%`;
                }
                break;
            case 'capeb-nombre-collaborateur-etp-dep':
            case 'capeb-nombre-collaborateur-etp-reg':
                slice.label = `Valeur comprise entre ${slice.min} et ${slice.max}`;
                if (isFirst) {
                    slice.label = `Valeur inférieure à ${slice.max}`;
                }
                if (isLast) {
                    slice.label = `Valeur supérieure à ${slice.min}`;
                }
                break;
            case 'resultat-net-dep':
            case 'capeb-resultat-net-reg':
                slice.label = `Résultat net \n entre ${slice.min} € et ${slice.max} €`;
                if (isFirst) {
                    slice.label = `Résultat net \n inférieur à ${slice.max} €`;
                }
                if (isLast) {
                    slice.label = `Résultat net \n supérieur à  ${slice.min} €`;
                }
                break;
            case 'capeb-cotisations-syndicales-totales-dep':
            case 'capeb-cotisations-syndicales-totales-reg':
                slice.label = `Cotisations syndicales totales \n entre ${slice.min} € et ${slice.max} €`;
                if (isFirst) {
                    slice.label = `Cotisations syndicales totales \n inférieures à ${slice.max} €`;
                }
                if (isLast) {
                    slice.label = `Cotisations syndicales totales \n supérieures à ${slice.min} €`;
                }
              break;
            case 'capeb-montant-cotisation-fixe-dep':
            case 'capeb-montant-cotisation-fixe-reg':
                slice.label = `Part fixe unitaire de ${slice.min} € à ${slice.max} €`;
                if (isFirst) {
                    slice.label = `Part fixe unitaire inférieure à ${slice.max} €`;
                }
                if (isLast) {
                    slice.label = `Part fixe unitaire supérieure à ${slice.min} €`;
                }
                break;
            case 'capeb-montant-cotisation-variable-dep':
            case 'capeb-montant-cotisation-variable-reg':
                slice.label = `Part variable unitaire de ${slice.min}% à ${slice.max}%`;
                if (isFirst) {
                    slice.label = `Part variable unitaire inférieure à ${slice.max}%`;
                }
                if (isLast) {
                    slice.label = `Part variable unitaire supérieure à ${slice.min}%`;
                }
                break;
            default:
                slice.label = `De ${slice.min}% à ${slice.max}%`;
                break;
        }

        return slice;
    });
};

// -----------------------------------
// Définie le texte de chaque slice de la légende
// -----------------------------------
const buildSlicesAttrs = (slices, graphType, type, colors) => {
    if (type !== 'area') {
        return slices;
    }

    let primary = '#c6362d';
    let secondary = '#dc9124';
    let plus = '#5ea895';
    let minus = '#c6362d';
    let mapColors = null;
    if (colors) {
        primary = colors.primary;
        secondary = colors.secondary;
        plus = colors.plus;
        minus = colors.minus;
        mapColors = colors.mapColors;
    }

    return slices.map((slice, i) => {
        switch (graphType) {
            case 'capeb-nombre-adherent-et-evolution-dep':
            case 'capeb-taux-syndicalisation-dep':
            case 'charge-personnelle-charge-exploitation-dep':
            case 'capeb-taux-depart-dep':
            case 'capeb-taux-nouveaux-adherents-dep':
            case 'capeb-pourcentage-adherent-travaillant-dep':
            case 'capeb-pourcentage-adherents-employeurs-dep':
            case 'capeb-pourcentage-adherents-employeurs-nombre-adherents-dep':
            case 'capeb-pourcentage-adherents-travaillant-employeurs-dep':
                if (i < 2) {
                    slice.attrsHover = slice.attrs = {
                        fill: slice.color, //stepColor(2 - i, 2, minus),
                        stroke: mapColors ? mapColors.stroke : '#eee',
                    };
                } else {
                    slice.attrsHover = slice.attrs = {
                        fill: slice.color, //stepColor(i - 1, 2, plus),
                        stroke: mapColors ? mapColors.stroke : '#eee',
                    };
                }
                break;
            default:
                slice.attrsHover = slice.attrs = {
                    fill: slice.color, // stepColor(i + 1, slices.length, secondary),
                    stroke: mapColors ? mapColors.stroke : '#eee',
                };
                break;
        }
        return slice;
    });
};

// -----------------------------------
//
// -----------------------------------
const buildLegendSlices = (values, graphType, colors, type = 'area') => {
    if (values.length == 0) {
        return [];
    }

    const tmp = scindeValues(values, graphType);
    let inferior = tmp.inferior;
    let superior = tmp.superior;
    if (['capeb-nombre-adherent-et-evolution-dep', 'capeb-nombre-adherent-et-evolution-reg'].indexOf(graphType) >= 0) {
        inferior = values.filter((value) => value < 0);
        superior = values.filter((value) => value >= 0);
    }
    const slicesInferior = scindeValues(inferior, graphType);
    const slicesSuperior = scindeValues(superior, graphType);
    
    let slicesColors = ['#EF4734', '#FBB771', '#D5EBD3', '#50BD82'];
    if ([
      'capeb-taux-depart-dep', 
      'capeb-taux-depart-reg',
      'charge-personnelle-charge-exploitation-dep',
      'charge-personnelle-charge-exploitation-reg',
      'capeb-charge-personnelle-charge-exploitation-dep',
      'capeb-charge-personnelle-charge-exploitation-reg',
    ].indexOf(graphType) >= 0) {
        slicesColors = slicesColors.reverse();
    }
    

    let slices = [
        {
            min: slicesInferior.min,
            max: slicesInferior.average,
            color: slicesColors[0],
        },
        {
            min: slicesInferior.average,
            max: slicesInferior.max,
            color: slicesColors[1],
        },
        {
            min: slicesInferior.max,
            max: slicesSuperior.average,
            color: slicesColors[2],
        },
        {
            min: slicesSuperior.average,
            max: slicesSuperior.max,
            color: slicesColors[3],
        },
    ];

    // Slices labels
    slices = buildSlicesLabel(slices, graphType, type);

    // Slices attrs
    slices = buildSlicesAttrs(slices, graphType, type, colors);

    delete slices[0].min;
    delete slices[slices.length - 1].max;

    return slices;
};

const getAreaAttrsFromSlices = (value, slices) => {
    if (value === null || value === undefined) {
        return null;
    }

    for (let slice of slices) {
        if (slice.min !== undefined && slice.max !== undefined) {
            if (slice.min <= value && value < slice.max) {
                return {
                    attrs: slice.attrs,
                    attrsHover: slice.attrsHover,
                };
            }
        } else if (slice.min === undefined && !Number.isNaN(slice.max)) {
            if (value < slice.max) {
                return {
                    attrs: slice.attrs,
                    attrsHover: slice.attrsHover,
                };
            }
        } else if (slice.max === undefined && !Number.isNaN(slice.min)) {
            if (slice.min <= value) {
                return {
                    attrs: slice.attrs,
                    attrsHover: slice.attrsHover,
                };
            }
        }
    }

    return null;
};

// -----------------------------------
//
// -----------------------------------
const buildAreas = (items, graphType, slices) => {
    const areas = {};

    items.forEach(({ code, name, nonDefinie, selected, ...item }) => {
        let total = customRound(item.total, graphType);

        if ([
          'capeb-nombre-collaborateur-etp-reg',
          'capeb-nombre-collaborateur-etp-dep',
        ].indexOf(graphType) >= 0) {
            total = customRound(item.collaborateurs || 0, graphType);
            nonDefinie = item.collabNonDefinie;
        }

        const area = {
            value: total,
        };

        if (selected) {
            area.attrs = area.attrsHover = selectedAreaAttrs;
        } else if (nonDefinie) {
            area.attrs = area.attrsHover = nrAreaAttrs;
        } else {
            // Récupère les attrs et attrsHover correspondant à la zone
            const areaAttrs = getAreaAttrsFromSlices(total, slices);
            if (areaAttrs) {
                const { attrs, attrsHover } = areaAttrs;
                area.attrs = attrs;
                area.attrsHover = attrsHover;
            }
        }

        switch (graphType) {
            case 'capeb-taux-mise-oeuvre-global-dep':
            case 'capeb-taux-mise-oeuvre-global-reg':
                area.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b> ${nonDefinie ? 'NR' : total + '%'}`,
                };
                area.text = {
                    content: `${nonDefinie ? 'NR' : total + '%'}`,
                };
                break;
            case 'capeb-nombre-adherent-et-evolution-dep':
                area.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b><br />
            <small><b>Evolution:</b></small> ${nonDefinie ? 'NR' : total + '%'}<br />
            <small><b>Adhérents en ${item.year}:</b></small> ${item.current.nonDefinie ? 'NR' : item.current.total}`,
                };
                area.text = {
                    content: `${item.current.nonDefinie ? 'NR' : item.current.total}`,
                };
                break;
            case 'capeb-taux-syndicalisation-dep':
            case 'capeb-charge-personnelle-charge-exploitation-reg':
            case 'charge-personnelle-charge-exploitation-dep':
            case 'capeb-taux-depart-dep':
            case 'capeb-taux-nouveaux-adherents-dep':
            case 'capeb-pourcentage-adherent-travaillant-dep':
            case 'capeb-pourcentage-adherents-employeurs-dep':
            case 'capeb-pourcentage-adherents-employeurs-nombre-adherents-dep':
            case 'capeb-pourcentage-adherents-travaillant-employeurs-dep':
            case 'capeb-montant-cotisations-syndicales-totales-produits-exploitation-dep':
            case 'capeb-montant-cotisations-syndicales-totales-produits-exploitation-reg':
                area.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b> ${nonDefinie ? 'NR' : total + '%'}`,
                };
                area.text = {
                    content: `${nonDefinie ? 'NR' : total + '%'}`,
                };
                break;
            case 'capeb-nombre-collaborateur-etp-dep':
                area.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b><br />
            <small><b>Ratio:</b></small> ${nonDefinie ? 'NR' : customRound(item.total, graphType)}<br />
            <small><b>Adhérents:</b></small> ${item.adhNonDefinie ? 'NR' : item.adherents}<br />
            <small><b>Collaborateurs:</b></small> ${
                item.collabNonDefinie ? 'NR' : customRound(item.collaborateurs, graphType)
            }`,
                };
                break;
            case 'capeb-nombre-collaborateur-etp-reg':
                area.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b><br />
            <small><b>Collaborateurs:</b></small> ${
                item.collabNonDefinie ? 'NR' : customRound(item.collaborateurs, graphType)
            }`,
                };
                break;
            case 'capeb-montant-cotisation-fixe-dep':
            case 'capeb-cotisations-syndicales-totales-dep':
            case 'resultat-net-dep':
            case 'capeb-cotisations-syndicales-totales-reg':
            case 'capeb-resultat-net-reg':
            case 'capeb-montant-cotisation-fixe-reg':
                area.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b> ${nonDefinie ? 'NR' : total + '€'}`,
                };
                area.text = {
                    content: `${nonDefinie ? 'NR' : total + '€'}`,
                };
                break;
            case 'capeb-subventions-contributions-exportation-produits-exportations-reg':
            case 'capeb-montant-cotisation-variable-dep':
            case 'capeb-montant-cotisation-variable-reg':
                area.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b> ${nonDefinie ? 'NR' : total + '%'}`,
                };
                area.text = {
                    content: `${nonDefinie ? 'NR' : total + '%'}`,
                };
                break;
        }

        // area.text.position = 'inner';

        if (code === '54') {
            if (!area.text) {
                area.text = {};
            }
            area.text.margin = {
                x: 0,
                y: 30,
            };
            areas['z-' + code] = area;
        } else {
            areas['z-' + code] = area;
        }
    });

    return areas;
};

// -----------------------------------
//
// -----------------------------------
const buildPlots = (items, graphType, colors) => {
    const plots = {};

    if (
        [
            'capeb-nombre-collaborateur-etp-dep',
            'capeb-nombre-collaborateur-etp-reg'
        ].indexOf(graphType) >= 0
    ) {
        items.forEach(({ code, name, nonDefinie, total, collaborateurs, ...item }) => {
            total = customRound(total, graphType);
            collaborateurs = customRound(collaborateurs, graphType);
            const plot = {
                value: collaborateurs,
                size: Math.min(Math.max((collaborateurs || 0) * 2.5, 15), 50),
                plotsOn: 'z-' + code,
                attrs: getDefaultPlotAttrs(graphType, colors),
                attrsHover: getDefaultPlotAttrs(graphType, colors),
                text: {
                    content: `${item.collabNonDefinie ? 'NR' : collaborateurs}`,
                    position: 'inner',
                    attrs: {
                        fill: '#fff',
                        stroke: '#000',
                        'stroke-width': 0,
                    },
                    attrsHover: {
                        fill: '#fff',
                        stroke: '#000',
                        'stroke-width': 0,
                    },
                },
            };
            

            switch (graphType) {
                case 'capeb-nombre-collaborateur-etp-dep':
                  // case 'capeb-nombre-collaborateur-etp-reg':
                  plot.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b><br />
                      <small><b>Ratio:</b></small> ${nonDefinie ? 'NR' : total}<br />
                      <small><b>Adhérents:</b></small> ${item.adhNonDefinie ? 'NR' : item.adherents}<br />
                      <small><b>Collaborateurs:</b></small> ${item.collabNonDefinie ? 'NR' : collaborateurs}`,
                  };
                  break;
                case 'capeb-nombre-collaborateur-etp-reg':
                  plot.tooltip = {
                    content: `<b style="text-decoration: underline">${name} :</b><br />
                      <small><b>Collaborateurs:</b></small> ${
                      item.collabNonDefinie ? 'NR' : collaborateurs
                    }`,
                  };
                  break;
            }

            plots['z-' + code] = plot;
        });
    }

    return plots;
};

// -----------------------------------
//
// -----------------------------------
const buildLegend = (items, graphType, colors) => {
    const legend = {
        area: {
            display: true,
            labelAttrs: {
              'font-size': '10px',
            },
            slices: [],
        },
        plot: {
            display: false,
            labelAttrs: {
              'font-size': '10px',
            },
            slices: [],
        },
    };

    if (!items || !items.length) {
        return legend;
    }

    let values = items.filter(({ code, total, nonDefinie }) => !nonDefinie).map(({ total }) => total);
    if ([
      'capeb-nombre-collaborateur-etp-reg',
      'capeb-nombre-collaborateur-etp-dep',
    ].indexOf(graphType) >= 0) {
        values = items
            .filter(({ code, collaborateurs, collabNonDefinie }) => !collabNonDefinie)
            .map(({ collaborateurs }) => collaborateurs);
    }
    legend.area.slices = buildLegendSlices(values, graphType, colors, 'area');

    switch (graphType) {
        case 'capeb-nombre-collaborateur-etp-dep':
        case 'capeb-nombre-collaborateur-etp-reg':
            legend.area.title = `Nombre de collaborateurs ETP`;
            break;
    }

    return legend;
};

// --------------------------------------------------------------
// Main Component
// --------------------------------------------------------------
const CapebMap = ({ graph, total, className = '', ...props }) => {
    const { colors } = useProjectContext();
    const [mapConfig, setMapConfig] = useState({});

    // -----------------------------------
    // Lorsque le total change
    // -----------------------------------
    useEffect(() => {
        buildMapConfig();
    }, [graph, total]);

    // -----------------------------------
    //
    // -----------------------------------
    const buildMapConfig = () => {
        const items = total && total.items ? total.items : [];
        const legend = buildLegend(items, graph.type, colors);
        const areaSliceWithoutMinMax = legend.area.slices.map(({ min, max, ...rest }) => {
            return {
                ...rest,
                min: -9999999,
                max: -9999998,
            };
        });
        areaSliceWithoutMinMax.push({
            attrs: nrAreaAttrs,
            attrsHover: nrAreaAttrs,
            min: -9999999,
            max: -9999998,
            label: 'Pas de réponse',
        });
        const config = {
            legend: {
                ...legend,
                area: {
                    ...legend.area,
                    slices: areaSliceWithoutMinMax,
                },
            },
            map: {
                defaultArea: {
                    attrs: {
                        fill: '#636362',
                        stroke: '#fff',
                    },
                    attrsHover: {
                        fill: '#636362',
                        stroke: '#fff',
                    },
                    text: {
                        attrs: {
                            fill: '#000',
                            'font-family': 'Arial',
                            'font-weight': 'bold',
                        },
                        attrsHover: {
                            fill: '#000',
                            'font-family': 'Arial',
                            'font-weight': 'bold',
                        },
                    },
                },
            },
            plots: buildPlots(items, graph.type, colors),
            areas: buildAreas(items, graph.type, legend.area.slices),
        };

        setMapConfig(config);
    };

    return (
        <React.Fragment>
            {[
                'capeb-taux-mise-oeuvre-global-reg',
                'capeb-nombre-collaborateur-etp-reg',
                'capeb-montant-cotisation-fixe-reg',
                'capeb-cotisations-syndicales-totales-reg',
                'capeb-resultat-net-reg',
                'capeb-subventions-contributions-exportation-produits-exportations-reg',
                'capeb-montant-cotisations-syndicales-totales-produits-exploitation-reg',
                'capeb-charge-personnelle-charge-exploitation-reg',
                'capeb-montant-cotisation-variable-reg',
            ].indexOf(graph.type) >= 0 ? (
                <MapReg config={mapConfig} />
            ) : (
                <MapDep config={mapConfig} />
            )}
        </React.Fragment>
    );
};

export default CapebMap;
